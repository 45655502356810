<template>
    <div class="c-navbar">
        <!-- middle: title -->
        <div class="d-flex justify-content-center align-items-center" style="position: absolute; inset: 0;">
            <transition :name="$route.meta.transition" :css="$route.meta.transition != null">
                <div :key="$route.path" style="position: absolute;">
                    <span style="font-size: 1.1em; font-weight: 500;">{{ $route.meta.title }}</span>
                </div>
            </transition>
        </div>
        
        <!-- left: back button -->
        <div class="d-flex align-items-center" style="position: absolute; top: 0; left: 0; bottom: 0;">
            <template v-if="showDesktopHome">
                <div class="dropdown">
                    <a
                        class="d-flex justify-content-center align-items-center dropdown-toggle"
                        style="margin-right: -0.5rem; height: 100%; width: 4rem; color: white; text-decoration: none; font-size: 0.9em; white-space: nowrap; gap: 0.25rem;"
                        href="/"
                        data-bs-toggle="dropdown"
                    >
                        <i class="fas fa-home" style="font-size: 1.25em;" />
                    </a>
                    <ul class="dropdown-menu">
                        <li><router-link class="dropdown-item" to="/">Mobile Home</router-link></li>
                        <li><a class="dropdown-item" href="/">Desktop Home</a></li>
                    </ul>
                </div>
            </template>
            <MNavbarBackButton :style="leftStyle" v-if="!breadcrumb" />
            <MNavbarBreadcrumb :style="leftStyle" v-else />
        </div>

        <!-- right: right slot -->
        <div class="d-flex align-items-center" style="position: absolute; top: 0; right: 0; bottom: 0;">
            <slot name="right" />
        </div>
    </div>
</template>

<script setup>
   import { computed } from "vue";
    import VueRouter from "vue-router";
    import { isMobile, isTablet, isDesktop } from "./utils.ts";

    import MNavbarBackButton from "./components.navbar.BackButton.vue";
    import MNavbarBreadcrumb from "./components.navbar.Breadcrumb.vue";

    const props = defineProps({
        breadcrumb: { type: Boolean },
    });

    const showDesktopHome = computed(() => isDesktop.value && !(isMobile.value || isTablet.value));

    const leftStyle = computed(() => {
        const style = {};
        if (showDesktopHome.value) {
            style.marginLeft = "4rem";
        }
        return style;
    });

    const router = VueRouter.useRouter();

    let routerMethod = null;

    for (let name of ["push", "replace", "go", "back", "forward"]) {
        const method = router[name];
        router[name] = async (...args) => {
            routerMethod = name;
            return await method.apply(router, args);
        };
    }

    function getRouteTransition() {
        if (routerMethod == null) {
            return null;
        }
        if (routerMethod === "back") {
            return "route-back";
        }
        return "route-forward";
    }

    router.beforeEach((to, from) => {
        to.meta.transition = getRouteTransition();
        routerMethod = null;
    });
</script>

<style scoped>
    .c-navbar {
        position: relative;
        background-color: steelblue;
        color: white;
        height: 3.5rem;
        flex-shrink: 0;
    }
</style>

<style>
    /* forward */
    .route-forward-enter-from {
        transform: translate(100%, 0%);
        opacity: 0%;
    }
    .route-forward-enter-active {
        transition: all 300ms ease-in-out;
    }
    .route-forward-enter-to {
        transform: translate(0%, 0%);
    }
    .route-forward-leave-from {
        transform: translate(0%, 0%);
    }
    .route-forward-leave-active {
        transition: all 300ms ease-in-out;
    }
    .route-forward-leave-to {
        transform: translate(-100%, 0%);
        opacity: 0%;
    }

    /* back */
    .route-back-enter-from {
        transform: translate(-100%, 0%);
        opacity: 0%;
    }
    .route-back-enter-active {
        transition: all 300ms ease-in-out;
    }
    .route-back-enter-to {
        transform: translate(0%, 0%);
    }
    .route-back-leave-from {
        transform: translate(0%, 0%);
    }
    .route-back-leave-active {
        transition: all 300ms ease-in-out;
    }
    .route-back-leave-to {
        transform: translate(100%, 0%);
        opacity: 0%;
    }
</style>

